import React, { useState } from 'react';
import axios from 'axios';
import "./App.css"

// const axios = require('axios').default;

const Form = () => {
  const [empID, setEmpID] = useState('');
  const [name, setName] = useState('');
  // const [message, setMessage] = useState(''); 
  const [gender, setGender] = useState('');
  const [deptAtJoining, setDepartmentatJoing] = useState('');
  const [doj, setDateofJoining] = useState('');
  const [workingStatus, setWorkingStatus] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [dob, setDateofBirth] = useState('');
  const [age, setAge] = useState('');
  const [designation, setDesignation] = useState('');
  const [hierarchy, setHierarchy] = useState('');
  const [ctc, setCTC] = useState('');
  const [subDepartment, setSubDepartment] = useState('');
  const [reportingManager, setReportingManager] = useState('');
  const [personalEID, setpersonalEID] = useState('');
  const [officialEID, setofficialEID] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  const [permanentAddress, setPermanentAddress] = useState('');
  const [pfNumber, setPFNumber] = useState('');
  const [aadhaarNumber, setAadhaarNumber] = useState('');
  const [uan, setUniversalAccountNumber] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportValidUpto, setPassportValidUpto] = useState('');
  const [lastOrganisationName, setLastOrganisationName] = useState('');
  const [lastDesignationHeld, setLastDesignationHeld] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [emergencyContactName, setEmergencyContactName] = useState('');
  const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [ifscCode, setIFSCCode] = useState('');
  const [bloodGroup, setBloodGroup] = useState('');
  const [fathersName, setFathersName] = useState('');
  const [fathersDOB, setFathersDOB] = useState('');
  const [mothersName, setMothersName] = useState('');
  const [mothersDOB, setMothersDOB] = useState('');
  const [spouseName, setSpouseName] = useState('');
  const [spouseDOB, setSpouseDOB] = useState('');
  const [kid1Name, setKid1Name] = useState('');
  const [kid1DOB, setKid1DOB] = useState('');
  const [kid2Name, setKid2Name] = useState('');
  const [kid2DOB, setKid2DOB] = useState('');
  const [kid3Name, setKid3Name] = useState('');
  const [kid3DOB, setKid3DOB] = useState('');
  const [resignationDate, setResignationDate] = useState('');
  const [exitDate, setExitDate] = useState('');
  const [covidInfected, setCovidInfected] = useState('');
  const [covidInfectedDate, setCovidInfectedDate] = useState('');
  const [covidRecoveryDate, setCovidRecoveryDate] = useState('');
  const [vaccinationStatus, setVaccinationStatus] = useState('');
  const [highestQualification, setHighestQualification] = useState('');
  const [totalYearsofExp, settotalYearsofExperience] = useState('');
  const [medicalInsurance, setMedicalInsurance] = useState('');
  const [cabFacility, setCabFacility] = useState('');
  const [loans, setLoans] = useState('');
  const [blacklist, setBlacklist] = useState('');
  const [idCard, setIDCard] = useState('');
  const [accessCard, setAccessCard] = useState('');
  const [extraPayDay, setExtraPayDay] = useState('');
  const [overtime, setOvertime] = useState('');


  const handleSubmit = (event) =>
  {
    event.preventDefault();
    // Send form data to backend
    axios.post('https://registrationformb-zkr4.onrender.com/submit', {
      empID: empID,
      name: name,
      // message: message,
      gender: gender,
      deptAtJoining: deptAtJoining,
      doj: doj,
      workingStatus: workingStatus,
      maritalStatus: maritalStatus,
      dob: dob,
      age: age,
      designation: designation,
      hierarchy: hierarchy,
      ctc: ctc,
      subDepartment: subDepartment,
      reportingManager: reportingManager,
      personalEID: personalEID,
      officialEID: officialEID,
      currentAddress: currentAddress,
      permanentAddress: permanentAddress,
      pfNumber: pfNumber,
      aadhaarNumber: aadhaarNumber,
      uan: uan,
      passportNumber: passportNumber,
      passportValidUpto: passportValidUpto,
      lastOrganisationName: lastOrganisationName,
      lastDesignationHeld: lastDesignationHeld,
      mobileNumber: mobileNumber,
      emergencyContactName: emergencyContactName,
      emergencyContactNumber: emergencyContactNumber,
      bankName: bankName,
      bankAccountNumber: bankAccountNumber,
      ifscCode: ifscCode,
      bloodGroup: bloodGroup,
      fathersName: fathersName,
      fathersDOB: fathersDOB,
      mothersName: mothersName,
      mothersDOB: mothersDOB,
      spouseName: spouseName,
      spouseDOB: spouseDOB,
      kid1Name: kid1Name,
      kid1DOB: kid1DOB,
      kid2Name: kid2Name,
      kid2DOB: kid2DOB,
      kid3Name: kid3Name,
      kid3DOB: kid3DOB,
      resignationDate: resignationDate,
      exitDate: exitDate,
      covidInfected: covidInfected,
      covidInfectedDate: covidInfectedDate,
      covidRecoveryDate: covidRecoveryDate,
      vaccinationStatus: vaccinationStatus,
      highestQualification: highestQualification,
      totalYearsofExp: totalYearsofExp,
      medicalInsurance: medicalInsurance,
      cabFacility: cabFacility,
      loans: loans,
      blacklist: blacklist,
      idCard: idCard,
      accessCard: accessCard,
      extraPayDay: extraPayDay,
      overTime: overtime,

    })
      // .then((alert) => {
      //   "Thank You, Your form has been submitted";
      // })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  

  return (
    <>
     <form onSubmit={handleSubmit} style={{ margin: '40px', padding: '10px'}}>

<div className="box"><label htmlFor="empID" style={{ display: 'block' }}>Employee ID:</label>
  <input type="string" id="empID" value={empID} onChange={(event) => setEmpID(event.target.value)}  style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box">
  <label htmlFor="name" style={{ display: 'block' }}>Name:</label>
  <input type="text" id="name" value={name} onChange={(event) => setName(event.target.value)}  required style={{ display: 'block', width: '100%', padding: '10px' }}></input>
</div>

<div className='box'>
  <label htmlFor="gender">Gender:</label>
  <div>
    <input type="radio" id="male" name="gender" value="male" onChange={(event) => setGender(event.target.value)} required />
    <label htmlFor="male">Male</label>
  </div>
  <div>
    <input type="radio" id="female" name="gender" value="female" onChange={(event) => setGender(event.target.value)} required />
    <label htmlFor="female">Female</label>
  </div>
  <div>
    <input type="radio" id="others" name="gender" value="others" onChange={(event) => setGender(event.target.value)} required />
    <label htmlFor="others">Others</label>
  </div></div>

  <div className='box'>
  <label htmlFor="maritalStatus">Marital Status:</label>
  <div>
    <input type="radio" id="married" name="maritalStatus" value="married" onChange={(event) => setMaritalStatus(event.target.value)} required />
    <label htmlFor="married">Married</label>
  </div>
  <div>
    <input type="radio" id="unmarried" name="maritalStatus" value="unmarried" onChange={(event) => setMaritalStatus(event.target.value)} required />
    <label htmlFor="unmarried">Unmarried</label>
 </div></div>

<div className="box">
  <label htmlFor="dob" style={{ display: 'block' }}>Date of Birth:</label>
  <input type="date" id="dob" value={dob} onChange={(event) => setDateofBirth(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="age" style={{ display: 'block' }}>Age:</label>
  <input type="integer" id="age" value={age} onChange={(event) => setAge(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

  <div className="box"><label htmlFor="highestQualification" style={{ display: 'block' }}>Highest Qualification:</label>
  <select id="highestQualification" value={highestQualification} onChange={(event) => setHighestQualification(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}>
    <option value="Graduation">Graduation</option>
    <option value="Masters">Masters</option>
  </select></div>

<div className="box"><label htmlFor="totalYearsofExp" style={{ display: 'block' }}>Total Years of Experience:</label>
  <input type="integer" id="totalYearsofExp" value={totalYearsofExp} onChange={(event) => settotalYearsofExperience(event.target.value)}  style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="currentAddress" style={{ display: 'block' }}>Current Address:</label>
  <input type="string" id="currentAddress" value={currentAddress} onChange={(event) => setCurrentAddress(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="permanentAddress" style={{ display: 'block' }}>Permanent Address:</label>
  <input type="string" id="permanentAddress" value={permanentAddress} onChange={(event) => setPermanentAddress(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="mobileNumber" style={{ display: 'block' }}>Mobile Number:</label>
  <input type="integer" id="mobileNumber" value={mobileNumber} onChange={(event) => setMobileNumber(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="emergencyContactName" style={{ display: 'block' }}>Emergency Contact Name:</label>
  <input type="text" id="emergencyContactName" value={emergencyContactName} onChange={(event) => setEmergencyContactName(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="emergencyContactNumber" style={{ display: 'block' }}>Emergency Contact Number:</label>
  <input type="integer" id="emergencyContactNumber" value={emergencyContactNumber} onChange={(event) => setEmergencyContactNumber(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="bloodGroup" style={{ display: 'block' }}>Blood Group:</label>
  <input type="string" id="bloodGroup" value={bloodGroup} onChange={(event) => setBloodGroup(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="fathersName" style={{ display: 'block' }}>Father's Name:</label>
  <input type="text" id="fathersName" value={fathersName} onChange={(event) => setFathersName(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="fathersDOB" style={{ display: 'block' }}>Father's DOB:</label>
  <input type="date" id="fathersDOB" value={fathersDOB} onChange={(event) => setFathersDOB(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="mothersName" style={{ display: 'block' }}>Mothers's Name:</label>
  <input type="text" id="mothersName" value={mothersName} onChange={(event) => setMothersName(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="mothersDOB" style={{ display: 'block' }}>Mother's DOB:</label>
  <input type="date" id="mothersDOB" value={mothersDOB} onChange={(event) => setMothersDOB(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="spouseName" style={{ display: 'block' }}>Spouse's Name:</label>
  <input type="text" id="spouseName" value={spouseName} onChange={(event) => setSpouseName(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="spouseDOB" style={{ display: 'block' }}>Spouse's DOB:</label>
  <input type="date" id="spouseDOB" value={spouseDOB} onChange={(event) => setSpouseDOB(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="kid1Name" style={{ display: 'block' }}>Kid 1 Name:</label>
  <input type="text" id="kid1Name" value={kid1Name} onChange={(event) => setKid1Name(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="kid1DOB" style={{ display: 'block' }}>Kid 1 DOB:</label>
  <input type="date" id="kid1DOB" value={kid1DOB} onChange={(event) => setKid1DOB(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="kid2Name" style={{ display: 'block' }}>Kid 2 Name:</label>
  <input type="text" id="kid2Name" value={kid2Name} onChange={(event) => setKid2Name(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="kid2DOB" style={{ display: 'block' }}>Kid 2 DOB:</label>
  <input type="date" id="kid2DOB" value={kid2DOB} onChange={(event) => setKid2DOB(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="kid3Name" style={{ display: 'block' }}>Kid 3 Name:</label>
  <input type="text" id="kid3Name" value={kid3Name} onChange={(event) => setKid3Name(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="kid3DOB" style={{ display: 'block' }}>Kid 3 DOB:</label>
  <input type="date" id="kid3DOB" value={kid3DOB} onChange={(event) => setKid3DOB(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

  <div className="box"><label htmlFor="deptAtJoining">Department at Joining:</label>
  <select id="deptAtJoining" value={deptAtJoining} onChange={(event) => setDepartmentatJoing(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}>
    <option value="Finance">Finance</option>
    <option value="Accounts">Accounts</option>
    <option value="Dispatch">Dispatch</option>
    <option value="Tracking">Tracking</option> 
    <option value="IT">IT</option> 
    </select>
  </div>

<div className="box"><label htmlFor="doj" style={{ display: 'block' }}>Date of Joining:</label>
  <input type="date" id="doj" value={doj} onChange={(event) => setDateofJoining(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="workingStatus">Working Status:</label>
  <select id="workingStatus" value={workingStatus} onChange={(event) => setWorkingStatus(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}>
    <option value="working">Working</option>
    <option value="resigned">Resigned</option>
    <option value="maternityLeave">Maternity Leave</option>
    <option value="LWP">LWP</option>
  </select></div>

  <div className="box"><label htmlFor="designation">Designation:</label>
  <select id="designation" value={designation} onChange={(event) => setDesignation(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}>
    <option value="trainee">Trainee</option>
    <option value="associate">Associate</option>
    <option value="seniorAssociate">Senior Associate</option>
    <option value="assistantManager">Assistant Manager</option>
    <option value="manager">Manager</option>
    <option value="assistantDirector">Assistant Director</option>
    <option value="director">Director</option>
  </select></div>

<div className="box"><label htmlFor="hierarchy" style={{ display: 'block' }}>Hierarchy:</label>
  <input type="integer" id="heirarchy" value={hierarchy} onChange={(event) => setHierarchy(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="ctc" style={{ display: 'block' }}>CTC:</label>
  <input type="integer" id="ctc" value={ctc} onChange={(event) => setCTC(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="subDepartment" style={{ display: 'block' }}>Sub Department:</label>
  <input type="text" id="subDepartment" value={subDepartment} onChange={(event) => setSubDepartment(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="reportingManager" style={{ display: 'block' }}>Reporting Manager:</label>
  <input type="text" id="reportingManager" value={reportingManager} onChange={(event) => setReportingManager(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="personalEID" style={{ display: 'block' }}>Personal Email ID:</label>
  <input type="email" id="personalEID" value={personalEID} onChange={(event) => setpersonalEID(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="officialEID" style={{ display: 'block' }}>Official Email ID:</label>
  <input type="email" id="officialEID" value={officialEID} onChange={(event) => setofficialEID(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="lastOrganisationName" style={{ display: 'block' }}>Last Organisation Name:</label>
  <input type="string" id="lastOrganisationName" value={lastOrganisationName} onChange={(event) => setLastOrganisationName(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="lastDesignationHeld" style={{ display: 'block' }}>Last Designation Held:</label>
  <input type="text" id="lastDesignationHeld" value={lastDesignationHeld} onChange={(event) => setLastDesignationHeld(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="bankName" style={{ display: 'block' }}>Bank Name:</label>
  <input type="text" id="bankName" value={bankName} onChange={(event) => setBankName(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="bankAccountNumber" style={{ display: 'block' }}>Bank Account Number:</label>
  <input type="integer" id="bankAccountNumber" value={bankAccountNumber} onChange={(event) => setBankAccountNumber(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="ifscCode" style={{ display: 'block' }}>IFSC Code:</label>
  <input type="string" id="ifscCode" value={ifscCode} onChange={(event) => setIFSCCode(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="pfNumber" style={{ display: 'block' }}>PF Number:</label>
  <input type="string" id="pfNumber" value={pfNumber} onChange={(event) => setPFNumber(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="aadhaarNumber" style={{ display: 'block' }}>Aadhaar Number:</label>
  <input type="integer" id="aadhaarNumber" value={aadhaarNumber} onChange={(event) => setAadhaarNumber(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="uan" style={{ display: 'block' }}>Universal Account Number:</label>
  <input type="string" id="uan" value={uan} onChange={(event) => setUniversalAccountNumber(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="passportNumber" style={{ display: 'block' }}>Passport Number:</label>
  <input type="string" id="passportNumber" value={passportNumber} onChange={(event) => setPassportNumber(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="passportValidUpto" style={{ display: 'block' }}>Passport Valid Upto:</label>
  <input type="date" id="passportValidUpto" value={passportValidUpto} onChange={(event) => setPassportValidUpto(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="resignationDate" style={{ display: 'block' }}>Resignation Date:</label>
  <input type="date" id="resignationDate" value={resignationDate} onChange={(event) => setResignationDate(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="exitDate" style={{ display: 'block' }}>Exit Date:</label>
  <input type="date" id="exitDate" value={exitDate} onChange={(event) => setExitDate(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="covidInfected" style={{ display: 'block' }}>Covid Infected:</label>
  <select id="covidInfected" value={covidInfected} onChange={(event) => setCovidInfected(event.target.value)} required style={{ display: 'block', width: '100%', padding: '10px' }}>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select></div>

<div className="box"><label htmlFor="covidInfectedDate" style={{ display: 'block' }}>Covid Infected Date:</label>
  <input type="date" id="covidInfectedDate" value={covidInfectedDate} onChange={(event) => setCovidInfectedDate(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="covidRecoveryDate" style={{ display: 'block' }}>Covid Recovery Date:</label>
  <input type="date" id="covidRecoveryDate" value={covidRecoveryDate} onChange={(event) => setCovidRecoveryDate(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="vaccinationStatus" style={{ display: 'block' }}>Vaccination Status:</label>
  <select
    id="vaccinationStatus"
    value={vaccinationStatus}
    onChange={(event) => setVaccinationStatus(event.target.value)}
    required
    style={{ display: 'block', width: '100%', padding: '10px' }}
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select></div>

<div className="box"><label htmlFor="medicalInsurance" style={{ display: 'block' }}>Medical Insurance:</label>
  <select
    id="medicalInsurance"
    value={medicalInsurance}
    onChange={(event) => setMedicalInsurance(event.target.value)}
    required
    style={{ display: 'block', width: '100%', padding: '10px' }}
  >
    <option value="generalInsurance">General Insurance</option>
    <option value="someOtherInsurance">Some Other Insurance</option>
  </select></div>

<div className="box"><label htmlFor="cabFacility" style={{ display: 'block' }}>Cab Facility:</label>
  <select
    id="cabFacility"
    value={cabFacility}
    onChange={(event) => setCabFacility(event.target.value)}
    required
    style={{ display: 'block', width: '100%', padding: '10px' }}
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select></div>

<div className="box"><label htmlFor="loans" style={{ display: 'block' }}>Loans:</label>
  <select
    id="loans"
    value={loans}
    onChange={(event) => setLoans(event.target.value)}
    required
    style={{ display: 'block', width: '100%', padding: '10px' }}
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select></div>

<div className="box"><label htmlFor="blacklist" style={{ display: 'block' }}>Blacklist:</label>
  <select
    id="blacklist"
    value={blacklist}
    onChange={(event) => setBlacklist(event.target.value)}
    required
    style={{ display: 'block', width: '100%', padding: '10px' }}
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select></div>

<div className="box"><label htmlFor="idCard" style={{ display: 'block' }}>ID Card:</label>
  <select
    id="idCard"
    value={idCard}
    onChange={(event) => setIDCard(event.target.value)}
    required
    style={{ display: 'block', width: '100%', padding: '10px' }}
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select></div>

<div className="box"><label htmlFor="accessCard" style={{ display: 'block' }}>Access Card:</label>
  <select
    id="accessCard"
    value={accessCard}
    onChange={(event) => setAccessCard(event.target.value)}
    required
    style={{ display: 'block', width: '100%', padding: '10px' }}
  >
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </select></div>

<div className="box"><label htmlFor="extraPayDay" style={{ display: 'block' }}>Extra Pay Day:</label>
<input type="string" id="extraPayDay" value={extraPayDay} onChange={(event) => setExtraPayDay(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>

<div className="box"><label htmlFor="overtime" style={{ display: 'block' }}>Overtime:</label>
  <input type="string" id="overtime" value={overtime} onChange={(event) => setOvertime(event.target.value)} style={{ display: 'block', width: '100%', padding: '10px' }}></input></div>
  
  <div id='submitButton' style={{ display:'flex', flexWrap:'wrap', justifyContent:'center' }}>
<input type="submit" className='button' value="Submit" style={{ textTransform:'capitalize', width: '300px', fontWeight: 'bold', padding: '10px', backgroundColor: '#4F75E5', color: '#fff', border: 'none', fontSize: '16px', cursor: 'pointer'  }}></input>
</div>
</form>

</>
   
  );
}


export default Form
